import React, { useEffect, useState } from 'react';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  TrophyTwoTone,
  ArrowUpOutlined,
  ArrowDownOutlined,
  MinusOutlined,
  InfoCircleOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import {
  List,
  Col,
  Table as TableAntd,
  Button as ButtonAntd,
  Tooltip,
  Radio,
  Badge,
  Button,
  Space,
  message,
  Statistic,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import BoxSubItem from '~/components/BoxSubItem';
import Row from '~/components/Row';
import { formatPrice } from '~/Utils';
import './styles.css';

import { paginationEstimates } from '~/services/hooks/estimates';
import { paginationLaborTypes } from '~/services/hooks/laborTypes';
import { paginationSubVendors } from '~/services/hooks/subVendors';
import { findAllEstimateSubVendors, updateEstimateSubVendor } from '~/services/hooks/estimateSubVendors';
import ObsModal from './obsModal';

export default function PriceQuotes({ project, customer }) {
  const projectId = project.id;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedEstimateSubVendors, setSelectedEstimateSubVendors] = useState([]);
  const [completeEstimate, setCompleteEstimate] = useState([]);
  const [allSubVendors, setAllSubVendors] = useState([]);
  const [allLaborTypes, setAllLaborTypes] = useState([]);
  const [laborTypes, setLaborTypes] = useState([]);
  const [showType, setShowType] = useState(1);
  const [winners, setWinners] = useState([]);
  const [estimateData, setEstimateData] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});

  const fetchData = async () => {
    setLoading(true);
    const data = await paginationEstimates({
      page: 0,
      maxSize: true,
      filters: [{ columnJoin: 'project', field: 'id', value: projectId, restriction: 'EQUAL' }],
    });
    if (data.numberOfElements === 0) {
      message.error('Not found');
    } else {
      setEstimateData(data.content[0]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    paginationSubVendors({
      page: 0,
      maxSize: true,
      filters: [{ field: 'type', value: 'SUBCONTRACTOR', restriction: 'EQUAL' }],
    }).then(data => {
      setAllSubVendors(data.content);
    });
    paginationLaborTypes({ page: 0, maxSize: true, filters: [] }).then(data => {
      setAllLaborTypes(data.content);
    });
  }, []);

  useEffect(() => {
    findAllEstimateSubVendors([
      {
        columnJoin: 'estimateItem',
        columnSecondJoin: 'estimate',
        field: 'id',
        value: estimateData.id,
        restriction: 'EQUAL',
      },
    ]).then(data => {
      setSelectedEstimateSubVendors(data);
    });
  }, [estimateData]);

  useEffect(() => {
    // set winners with selectedEstimateSubVendors that have winner = true
    const newWinners = [];
    const winnersItems = selectedEstimateSubVendors.filter(estimateSubvendor => estimateSubvendor.winner === true);
    winnersItems.forEach(item => {
      const winner = {};
      winner.subVendorId = item.subVendor.id;
      winner.laborTypeId = item.estimateItem.laborType.id;
      // insert winner in newWinners if not exists
      if (
        !newWinners.find(
          winnerItem => winnerItem.subVendorId === winner.subVendorId && winnerItem.laborTypeId === winner.laborTypeId
        )
      ) {
        newWinners.push(winner);
      }
    });
    if (newWinners.length > 0) {
      setWinners(newWinners);
    }
  }, [selectedEstimateSubVendors]);

  // mount a list of selectedEstimateSubvendors with subVendor and estimateItem data
  const mountEstimateSubvendors = () => {
    const complete = [];
    selectedEstimateSubVendors.forEach(element => {
      element.subVendor = allSubVendors.find(subVendor => subVendor.id === element.subvendor_id); // get data from subvendors by subvendor_id
      element.estimateItem = completeEstimate.items.find(estimateItem => estimateItem.id === element.estimate_item_id); // get data from estimate_items by estimate_item_id
      complete.push(element);
    });
    setSelectedEstimateSubVendors(complete);
  };

  // calc final date from given workdays number
  const calcFinalDate = workdays => {
    const startDate = estimateData.sent;
    var moment = require('moment-business-days');
    moment.updateLocale('en', { workingWeekdays: [1, 2, 3, 4, 5] });
    const finalDate = moment(startDate, 'YYYY-MM-DD')
      .businessAdd(workdays)
      .format('YYYY-MM-DD');
    return finalDate;
  };

  // mount a list of uniques subvendors that are in estimate_subvendors
  const mountSubvendors = () => {
    const listSubvendors = [];
    allSubVendors.forEach(element => {
      if (selectedEstimateSubVendors.some(estimateSubvendor => estimateSubvendor.subvendor_id === element.id)) {
        listSubvendors.push(element);
      }
    });
    setAllSubVendors(listSubvendors);
  };

  // mount a list of uniques laborTypes that are in selectedEstimateSubvendors.estimateItem.laborType
  const mountLaborTypes = () => {
    const listLaborTypes = [];
    allLaborTypes.forEach(element => {
      if (
        selectedEstimateSubVendors.some(estimateSubvendor => estimateSubvendor.estimateItem.laborType.id === element.id)
      ) {
        listLaborTypes.push(element);
      }
    });
    setLaborTypes(listLaborTypes);
  };

  // mount a list subvendors with their items
  const mountCompleteEstimate = () => {
    const complete = [];
    const laborType = laborTypes.find(laborType => laborType.id === showType);
    if (allSubVendors.length === 0 || !laborType) return;
    allSubVendors.forEach(subVendor => {
      const items = [];
      selectedEstimateSubVendors
        .filter(estimateSubvendor => estimateSubvendor.subVendor?.id === subVendor.id)
        .filter(estimateSubvendor => estimateSubvendor.estimateItem.laborType?.id === laborType.id)
        .forEach(estimateSubvendor => {
          const item = {
            id: estimateSubvendor.estimateItem.id,
            description: estimateSubvendor.estimateItem.estimateDescription.description,
            unit: estimateSubvendor.estimateItem.estimateDescription.unit,

            ownQuantity: estimateSubvendor.estimateItem.quantity,
            ownMaterialValue: estimateSubvendor.estimateItem.materialValue,
            ownLaborValue: estimateSubvendor.estimateItem.laborValue,

            quantity: estimateSubvendor.quantity,
            materialValue: estimateSubvendor.materialValue,
            laborValue: estimateSubvendor.laborValue,

            projectId: projectId,
            area: estimateSubvendor.estimateItem.area,
            type: estimateSubvendor.estimateItem.type,
            laborType: estimateSubvendor.estimateItem.laborType,
            dueDate: estimateSubvendor.dueDate,
            note: estimateSubvendor.note,
            winner: estimateSubvendor.winner,
          };
          items.push(item);
        });
      if (items.length > 0) {
        complete.push({ subVendor, items });
      }
    });
    setCompleteEstimate(complete);
  };

  // calculate the total value for each subVendor by laborTypeId
  const calculateTotal = (subVendor, laborTypeId) => {
    let total = 0;
    selectedEstimateSubVendors
      .filter(estimateSubvendor => estimateSubvendor.subvendor_id === subVendor.id)
      .forEach(estimateSubvendor => {
        if (estimateSubvendor.estimateItem.laborType.id === laborTypeId) {
          total += estimateSubvendor.value;
        }
      });
    return total;
  };

  useEffect(() => {
    mountEstimateSubvendors();
    mountSubvendors();
  }, []);

  useEffect(() => {
    if (selectedEstimateSubVendors.length > 0 && allLaborTypes.length > 0) mountLaborTypes();
  }, [selectedEstimateSubVendors, allLaborTypes]);

  useEffect(() => {
    if (allSubVendors.length > 0 && laborTypes.length > 0) mountCompleteEstimate();
  }, [allSubVendors, laborTypes, showType]);

  const handleWinner = (idLaborType, idSubvendor) => {
    let findWinner = false;
    const newWinners = winners.filter(winner => winner.laborTypeId !== idLaborType);
    if (!winners.find(winner => winner.laborTypeId === idLaborType && winner.subVendorId === idSubvendor)) {
      const winner = { laborTypeId: idLaborType, subVendorId: idSubvendor };
      findWinner = true;
      newWinners.push(winner);
    }
    setWinners(newWinners);

    const newEstimateSubvendors = selectedEstimateSubVendors.map(estimate => {
      if (estimate.subVendor.id === idSubvendor && estimate.estimateItem.laborType.id === idLaborType) {
        estimate.winner = findWinner;
      }
      if (estimate.subVendor.id !== idSubvendor && estimate.estimateItem.laborType.id === idLaborType) {
        estimate.winner = false;
      }
      return estimate;
    });
    setSelectedEstimateSubVendors(newEstimateSubvendors);
  };

  const handleSaveWinners = () => {
    const subVendorsWinners = selectedEstimateSubVendors.filter(estimateSubvendor => estimateSubvendor.winner === true);
    subVendorsWinners.forEach(async subVendorWinner => {
      await updateEstimateSubVendor(subVendorWinner);
    });
  };

  const handleNoteModal = record => {
    setSelectedRecord(record);
    setVisible(true);
  };

  const priceIndicator = (newValue, value) => {
    if (value === 0 || newValue === 0) return null;
    const percentage = ((newValue - value) / value) * 100;
    if (percentage > 0) {
      return (
        <Statistic
          value={percentage}
          valueStyle={{ color: 'red', fontSize: '12px' }}
          precision={2}
          prefix={
            <>
              <ArrowUpOutlined />
            </>
          }
          suffix="%"
        />
      );
    }
    if (percentage < 0) {
      return (
        <Statistic
          value={percentage}
          precision={2}
          valueStyle={{ color: 'green', fontSize: '12px' }}
          prefix={
            <>
              <ArrowDownOutlined />
            </>
          }
          suffix="%"
        />
      );
    }
    return (
      <Statistic
        value={percentage}
        precision={2}
        valueStyle={{ color: 'gray', fontSize: '12px' }}
        prefix={
          <>
            <MinusOutlined />
          </>
        }
        suffix="%"
      />
    );
  };

  const tableColumns = [
    {
      title: t('screens:price_quotes.subvendor_name'),
      key: 'subVendor.name',
      ellipsis: { showTitle: false },
      align: 'left',
      render: record => {
        return (
          <Row>
            <Col flex="30px">
              {winners.some(winner => winner.subVendorId === record.subVendor.id) ? (
                <Badge
                  count={winners.filter(winner => winner.subVendorId === record.subVendor.id).length}
                  offset={[2, -2]}
                  color="green"
                  showZero={false}
                  size="small"
                  overflowCount={99}
                  // title={winners
                  //   .filter(winner => winner.subVendorId === record.subVendor.id)
                  //   .map(winner => winner.laborType.name)}
                >
                  <TrophyTwoTone twoToneColor="#52c41a" />
                </Badge>
              ) : null}
            </Col>
            <Col flex="auto">
              <Tooltip placement="topLeft" title={record.subVendor.companyName}>
                {record.subVendor.companyName}
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: t('screens:price_quotes.totalMaterialValue'),
      key: 'parentTotalMaterialValue',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => {
        // calc sum of all items material values times their quantity that laborType is equal to the current laborType and subVendor is equal to the current subVendor
        const sum = record.items
          ?.filter(
            item => item.laborType.id === showType,
            item => item.subVendor.id === record.subVendor.id
          )
          ?.reduce((acc, item) => acc + item.materialValue * item.quantity, 0);
        const ourSum = record.items
          ?.filter(
            item => item.laborType.id === showType,
            item => item.subVendor.id === record.subVendor.id
          )
          ?.reduce((acc, item) => acc + item.ownMaterialValue * item.ownQuantity, 0);
        return (
          <>
            <Row>
              <Col span={12}>Our</Col>
              <Col span={12}>Sent</Col>
            </Row>
            <Row>
              <Col span={12}>{formatPrice(ourSum)}</Col>
              <Col span={12}>{formatPrice(sum)}</Col>
            </Row>
            <Row>
              <Col span={12}></Col>
              <Col span={12}>{priceIndicator(sum, ourSum)}</Col>
            </Row>
          </>
        );
      },
    },
    {
      title: t('screens:price_quotes.totalLaborValue'),
      key: 'parentTotalLaborValue',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => {
        const sum = record.items
          ?.filter(
            item => item.laborType.id === showType,
            item => item.subVendor.id === record.subVendor.id
          )
          ?.reduce((acc, item) => acc + item.laborValue * item.quantity, 0);

        const ourSum = record.items
          ?.filter(
            item => item.laborType.id === showType,
            item => item.subVendor.id === record.subVendor.id
          )
          ?.reduce((acc, item) => acc + item.ownLaborValue * item.ownQuantity, 0);

        return (
          <>
            <Row>
              <Col span={12}>Our</Col>
              <Col span={12}>Sent</Col>
            </Row>
            <Row>
              <Col span={12}>{formatPrice(ourSum)}</Col>
              <Col span={12}>{formatPrice(sum)}</Col>
            </Row>
            <Row>
              <Col span={12}></Col>
              <Col span={12}>{priceIndicator(sum, ourSum)}</Col>
            </Row>
          </>
        );
      },
    },
    {
      title: t('screens:price_quotes.totalMaterialAndLaborValue'),
      key: 'parentTotalMaterialAndLaborValue',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => {
        const sum = record.items
          ?.filter(
            item => item.laborType.id === showType,
            item => item.subVendor.id === record.subVendor.id
          )
          ?.reduce((acc, item) => acc + (item.laborValue + item.materialValue) * item.quantity, 0);

        const ourSum = record.items
          ?.filter(
            item => item.laborType.id === showType,
            item => item.subVendor.id === record.subVendor.id
          )
          ?.reduce((acc, item) => acc + (item.ownLaborValue + item.ownMaterialValue) * item.ownQuantity, 0);

        return (
          <>
            <Row>
              <Col span={12}>Our</Col>
              <Col span={12}>Sent</Col>
            </Row>
            <Row>
              <Col span={12}>{formatPrice(ourSum)}</Col>
              <Col span={12}>{formatPrice(sum)}</Col>
            </Row>
            <Row>
              <Col span={12}></Col>
              <Col span={12}>{priceIndicator(sum, ourSum)}</Col>
            </Row>
          </>
        );
      },
    },
    {
      title: t('screens:price_quotes.dueDate'),
      key: 'price_quotes.dueDate',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => {
        // max dueDate
        const maxDueDate = record.items
          ?.filter(item => item.laborType.id === showType)
          ?.reduce((acc, item) => {
            if (acc === null) return item.dueDate;
            if (item.dueDate === null) return acc;
            return acc > item.dueDate ? acc : item.dueDate;
          }, null);

        return (
          <Tooltip placement="topLeft" title={`Estimate Date: ${calcFinalDate(maxDueDate)}`}>
            {`${maxDueDate} business days`}
          </Tooltip>
        );
      },
    },
    {
      title: t('screens:price_quotes.items.winner'),
      key: 'winner',
      align: 'center',
      width: '100px',
      ellipsis: { showTitle: false },
      render: record => {
        return (
          <>
            <Tooltip placement="topLeft" title={t('screens:price_quotes.mark_as_winner')}>
              <Radio
                checked={winners.find(
                  winner => winner.laborTypeId === showType && winner.subVendorId === record.subVendor.id
                )}
                onChange={
                  () => handleWinner(showType, record.subVendor.id)
                }
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  const tableColumnsDetails = [
    {
      title: t('screens:price_quotes.items.description'),
      key: 'description',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => (
        <Tooltip placement="topLeft" title={record.description}>
          {record.description}
        </Tooltip>
      ),
    },
    {
      title: t('screens:price_quotes.items.area'),
      key: 'area.name',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => (
        <Tooltip placement="topLeft" title={record.area.name}>
          {record.area.name}
        </Tooltip>
      ),
    },
    {
      title: t('screens:price_quotes.items.unit'),
      dataIndex: 'unit',
      key: 'unit',
      width: '80px',
      ellipsis: { showTitle: false },
      align: 'center',
    },
    {
      title: t('screens:price_quotes.items.quantity'),
      key: 'quantity',
      width: '80px',
      ellipsis: {
        showTitle: true,
      },
      align: 'center',
      render: record => {
        const quantity = record.quantity;
        const ownQuantity = record.ownQuantity;
        return (
          <>
            <Row>
              <Col span={12}>Our</Col>
              <Col span={12}>Sent</Col>
            </Row>
            <Row>
              <Col span={12}>{ownQuantity}</Col>
              <Col span={12}>{quantity}</Col>
            </Row>
          </>
        );
      },
    },

    TableAntd.EXPAND_COLUMN,
    {
      title: t('screens:price_quotes.items.totalMaterialAndLabor'),
      key: 'totalMaterialAndLabor',
      width: '150px',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => {
        const total = (record.materialValue + record.laborValue) * record.quantity;
        const totalOwn = (record.ownMaterialValue + record.ownLaborValue) * record.quantity;
        const isExpanded = expandedRowKeys.find(key => key.description === record.description);
        return {
          props: {
            // style: { background: isExpanded ? 'green' : 'red' },
          },
          children: (
            <>
              {formatPrice(total)}
              {priceIndicator(total, totalOwn)}
            </>
          ),
        };
      },
    },
    {
      title: t('screens:price_quotes.items.dueDate'),
      key: 'dueDate',
      width: '100px',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => <>{`${record.dueDate} business days`}</>,
    },
    {
      title: t('screens:price_quotes.items.notes'),
      key: 'note',
      ellipsis: { showTitle: false },
      align: 'center',
      width: '100px',
      render: record => (
        <Space>
          {record.note && record.note.length > 0 && (
            <Tooltip title={t('screens:price_quotes.items.viewSubcontractorNote')}>
              <Button type="link" icon={<InfoCircleOutlined />} onClick={() => handleNoteModal(record)} />
            </Tooltip>
          )}
          {record.subVendorFile && (
            <Tooltip title={t('screens:price_quotes.items.downloadSubcontractorFile')} placement="bottom">
              <ButtonAntd type="link" icon={<DownloadOutlined />} />
            </Tooltip>
          )}
          {!(record.note || record.subVendorFile) && (
            <>N/A</>
          )}
        </Space>
      ),
    },
  ];

  const tableColumnsDetailsExpanded = [
    {
      title: t('screens:price_quotes.items.materialValue'),
      key: 'materialValue',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => (
        <>
          <Row>
            <Col
              span={24}
              style={{
                fontWeight: 'bold',
                color: '#444',
                marginBottom: '5px',
                borderBottom: '1px solid #ddd',
                paddingBottom: '5px',
              }}
            >
              {t('screens:price_quotes.items.materialValue')}
            </Col>
          </Row>
          <Row>
            <Col span={12}>Our</Col>
            <Col span={12}>Sent</Col>
            {/* <Divider type="horizontal" /> */}
          </Row>
          <Row>
            <Col span={12}>{formatPrice(record.ownMaterialValue)}</Col>
            <Col span={12}>{formatPrice(record.materialValue)}</Col>
          </Row>
          <Row>
            <Col span={12}></Col>
            <Col span={12}>{priceIndicator(record.materialValue, record.ownMaterialValue)}</Col>
          </Row>
        </>
      ),
    },
    {
      title: t('screens:price_quotes.items.laborValue'),
      key: 'laborValue',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => {
        return {
          props: {
            style: { fontSize: '12px' },
          },
          children: (
            <>
              <Row>
                <Col
                  span={24}
                  style={{
                    fontWeight: 'bold',
                    color: '#444',
                    marginBottom: '5px',
                    borderBottom: '1px solid #ddd',
                    paddingBottom: '5px',
                  }}
                >
                  {t('screens:price_quotes.items.materialValue')}
                </Col>
              </Row>
              <Row>
                <Col span={12}>Our</Col>
                <Col span={12}>Sent</Col>
              </Row>
              <Row>
                <Col span={12}>{formatPrice(record.ownLaborValue)}</Col>
                <Col span={12}>{formatPrice(record.laborValue)}</Col>
              </Row>
              <Row>
                <Col span={12}></Col>
                <Col span={12}>{priceIndicator(record.laborValue, record.ownLaborValue)}</Col>
              </Row>
            </>
          ),
        };
      },
    },
    {
      title: t('screens:price_quotes.items.totalMaterial'),
      key: 'totalMaterial',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => {
        const totalMaterial = record.materialValue * record.quantity;
        const totalOwnMaterial = record.ownMaterialValue * record.ownQuantity;
        return (
          <>
            <Row>
              <Col
                span={24}
                style={{
                  fontWeight: 'bold',
                  color: '#444',
                  marginBottom: '5px',
                  borderBottom: '1px solid #ddd',
                  paddingBottom: '5px',
                }}
              >
                {t('screens:price_quotes.items.totalMaterial')}
              </Col>
            </Row>
            <Row>
              <Col span={12}>Our</Col>
              <Col span={12}>Sent</Col>
            </Row>
            <Row>
              <Col span={12}>{formatPrice(totalOwnMaterial)}</Col>
              <Col span={12}>{formatPrice(totalMaterial)}</Col>
            </Row>
            <Row>
              <Col span={12}></Col>
              <Col span={12}>{priceIndicator(totalMaterial, totalOwnMaterial)}</Col>
            </Row>
          </>
        );
      },
    },
    {
      title: t('screens:price_quotes.items.totalLabor'),
      key: 'totalLabor',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => {
        const totalLabor = record.laborValue * record.quantity;
        const totalOwnLabor = record.ownLaborValue * record.ownQuantity;
        return (
          <>
            <Row>
              <Col
                span={24}
                style={{
                  fontWeight: 'bold',
                  color: '#444',
                  marginBottom: '5px',
                  borderBottom: '1px solid #ddd',
                  paddingBottom: '5px',
                }}
              >
                {t('screens:price_quotes.items.totalLabor')}
              </Col>
            </Row>
            <Row>
              <Col span={12}>Our</Col>
              <Col span={12}>Sent</Col>
            </Row>
            <Row>
              <Col span={12}>{formatPrice(totalOwnLabor)}</Col>
              <Col span={12}>{formatPrice(totalLabor)}</Col>
            </Row>
            <Row>
              <Col span={12}></Col>
              <Col span={12}>{priceIndicator(totalLabor, totalOwnLabor)}</Col>
            </Row>
          </>
        );
      },
    },
    {
      title: t('screens:price_quotes.items.totalMaterialAndLabor'),
      key: 'totalMaterialAndLabor',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => {
        const total = (record.materialValue + record.laborValue) * record.quantity;
        const totalOwn = (record.ownMaterialValue + record.ownLaborValue) * record.ownQuantity;
        return {
          props: {
            style: { backgroundColor: '#f9f9f9' },
          },
          children: (
            <>
              <Row>
                <Col
                  span={24}
                  style={{
                    fontWeight: 'bold',
                    color: '#444',
                    marginBottom: '5px',
                    borderBottom: '1px solid #ddd',
                    paddingBottom: '5px',
                  }}
                >
                  {t('screens:price_quotes.items.totalMaterialAndLabor')}
                </Col>
              </Row>
              <Row>
                <Col span={12}>Our</Col>
                <Col span={12}>Sent</Col>
              </Row>
              <Row>
                <Col span={12}>{formatPrice(totalOwn)}</Col>
                <Col span={12}>{formatPrice(total)}</Col>
              </Row>
              <Row>
                <Col span={12}></Col>
                <Col span={12}>{priceIndicator(total, totalOwn)}</Col>
              </Row>
            </>
          ),
        };
      },
    },
  ];

  const tableSummary = subvendorId => {
    const subvendorItems = selectedEstimateSubVendors
      .filter(i => i.subVendor.id === subvendorId)
      .filter(i => i.estimateItem.laborType.id !== showType);

    // group subvendorItems by subvendorItem.laborType.id
    // const groupedItems = subvendorItems.reduce((acc, item) => {
    //   const laborTypeId = item.estimateItem.laborType.id;
    //   if (!acc[laborTypeId]) {
    //     acc[laborTypeId] = [];
    //   }
    //   acc[laborTypeId].push(item);
    //   return acc;
    // }, {});

    return subvendorItems.length !== 0 ? (
      <TableAntd.Summary.Row>
        <TableAntd.Summary.Cell colSpan={7}>
          <BoxSubItem title="Other labor types">
            <TableAntd
              bordered
              rowClassName={record =>
                record.estimateItem.laborType.id === showType ? 'table-row' : 'table-row-hidden'
              }
              rowKey="id"
              pagination={false}
              loading={loading}
              columns={[
                {
                  title: t('screens:price_quotes.items.labor_type_name'),
                  key: 'estimateItem.laborType.name',
                  ellipsis: { showTitle: false },
                  render: record => record.estimateItem.laborType.name,
                },
                {
                  title: t('screens:price_quotes.items.description'),
                  key: 'estimateItem.name',
                  ellipsis: { showTitle: false },
                  render: record => record.estimateItem.description,
                },
                {
                  title: t('screens:price_quotes.items.area'),
                  key: 'estimateItem.area.name',
                  ellipsis: { showTitle: false },
                  render: record => record.estimateItem.area.name,
                },
                {
                  title: t('screens:price_quotes.items.unit'),
                  key: 'estimateItem.unit',
                  width: '80px',
                  ellipsis: { showTitle: false },
                  render: record => record.estimateItem.unit,
                },
                {
                  title: t('screens:price_quotes.items.quantity'),
                  key: 'estimateItem.quantity',
                  width: '80px',
                  ellipsis: { showTitle: false },
                  render: record => record.estimateItem.quantity,
                },
                {
                  title: t('screens:price_quotes.items.value'),
                  key: 'estimateItem.value',
                  width: '100px',
                  ellipsis: { showTitle: false },
                  render: record => <>{formatPrice(record.value)}</>,
                },
                {
                  title: t('screens:price_quotes.items.dueDate'),
                  key: 'estimateItem.dueDate',
                  width: '100px',
                  ellipsis: { showTitle: false },
                  render: record => <>{`${record.dueDate} business days`}</>,
                },
                {
                  title: t('screens:price_quotes.items.note'),
                  key: 'estimateItem.note',
                  ellipsis: { showTitle: false },
                  render: record => record.note,
                },
              ]}
              dataSource={subvendorItems}
              showHeader={true}
              size="small"
            />
          </BoxSubItem>
        </TableAntd.Summary.Cell>
      </TableAntd.Summary.Row>
    ) : null;
  };

  return (
    <>
      <Box>
        <Row>
          <Col span={4}>
            <List
              header={t('screens:price_quotes.quoted_services')}
              footer={
                // center button
                <ButtonAntd
                  block
                  type="primary"
                  disabled={winners.length < laborTypes.length}
                  onClick={() => handleSaveWinners()}
                >
                  {t('screens:price_quotes.save_winners')}
                </ButtonAntd>
              }
              bordered
              dataSource={laborTypes}
              renderItem={item => (
                <List.Item>
                  <ButtonAntd
                    icon={
                      winners.find(winner => winner.laborTypeId === item.id) ? (
                        <CheckCircleOutlined />
                      ) : (
                        <ExclamationCircleOutlined />
                      )
                    }
                    style={
                      winners.find(winner => winner.laborTypeId === item.id)
                        ? { color: '#22cc22' }
                        : { color: '#1890ff' }
                    }
                    type="link"
                    onClick={() => setShowType(item.id)}
                  >
                    <span style={{ fontWeight: showType === item.id ? 'bold' : 'normal' }}>{item.name}</span>
                  </ButtonAntd>
                </List.Item>
              )}
            />
          </Col>
          <Col span={20}>
            <Row gutter={[0, 20]}>
              <Col span={24}>
                <p style={{ paddingBottom: 5 }}>{laborTypes.find(laborType => laborType.id === showType)?.name}</p>
              </Col>
            </Row>
            <TableAntd
              bordered
              rowClassName={record =>
                winners.find(winner => winner.laborTypeId === showType && winner.subVendorId === record.subVendor.id)
                  ? 'table-parent-row-winner'
                  : 'table-parent-row'
              }
              // rowClassName="table-parent-row"
              rowKey={record => record.subVendor.id}
              pagination={false}
              loading={loading}
              columns={tableColumns}
              dataSource={completeEstimate}
              showHeader={true}
              expandable={{
                expandedRowRender: record => (
                  <TableAntd
                    // bordered
                    rowClassName={record => (record.laborType.id === showType ? 'table-row' : 'table-row-hidden')}
                    rowKey="id"
                    pagination={false}
                    loading={loading}
                    columns={tableColumnsDetails}
                    expandable={{
                      expandedRowRender: record => (
                        <TableAntd
                          bordered
                          rowClassName="table-row-detail-expanded"
                          rowKey="id"
                          pagination={false}
                          loading={loading}
                          columns={tableColumnsDetailsExpanded}
                          dataSource={[record]}
                          showHeader={false}
                          size="small"
                        />
                      ),
                    }}
                    dataSource={record.items}
                    showHeader={true}
                    size="small"
                    title={() => t('screens:price_quotes.items.details')}
                    summary={() => tableSummary(record.subVendor.id)}
                  />
                ),
                rowExpandable: record => record.name !== 'Not Expandable',
              }}
            />
          </Col>
        </Row>
      </Box>
      <ObsModal
        visible={visible}
        onCancel={() => setVisible(false) && setSelectedRecord(null)}
        selectedRecord={selectedRecord}
      />
    </>
  );
}
