import React from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, Col, Popconfirm } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Pagination from '~/components/Pagination';
import Row from '~/components/Row';
import { Table, TableActions } from '~/components/Table';
import DefaultLayout from '~/pages/_layouts/full';
import { BreadcrumbCustom } from '~/styles/global';
import AreaForm from './form';
import Filter from './filter';
import { paginationAreas, deleteArea } from '~/services/hooks/areas';

export default function Areas() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('DESC');
  const [filters, setFilters] = useState([]);

  const fetchData = async page => {
    setLoading(true);
    const data = await paginationAreas({
      page: page - 1,
      sortBy,
      sortDirection,
      filters: filters,
    });
    setTableData(data.content);
    const metaData = {
      first: data.first,
      last: data.last,
      numberOfElements: data.numberOfElements,
      pageNumber: data.pageable.pageNumber + 1,
      pageSize: data.pageable.pageSize,
      totalElements: data.totalElements,
    };
    setMeta(metaData);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
  }, [filters, sortBy]);

  const handleDelete = async id => {
    await deleteArea(id);
    fetchData(1);
  };

  const handleEdit = record => {
    setSelectedRecord(record);
    setShowForm(true);
  };

  const onUpdateItem = item => {
    const index = tableData.findIndex(i => i.id === item.id);
    if (index > -1) {
      const newData = [...tableData];
      newData[index] = item;
      setTableData(newData);
    }
  };

  const style = {
    breadcrumb: { margin: '16px 0' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
  };

  const tableColumns = [
    {
      title: t('screens:area.id'),
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      sorter: true,
      width: 200,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('screens:area.name'),
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      sorter: true,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('screens:area.actions'),
      key: 'actions',
      align: 'center',
      ellipsis: {
        showTitle: true,
      },
      className: 'table-action',
      render: record => (
        <TableActions>
          <Button onClick={() => handleEdit(record)} title={t('messages:edit')}>
            <EditOutlined />
          </Button>
          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button title={t('messages:delete')}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </TableActions>
      ),
    },
  ];

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item href="/">{t('menus:settings')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:Area')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  const handleTableChange = (sorter) => {
    const sortOrder = sorter.order === 'ascend' ? '' : 'DESC';
    if (sorter.field != sortBy || sortOrder != sortDirection) {
      setSortBy(sorter.field);
      setSortDirection(sortOrder);
      fetchData(1);
    }
  };

  return (
    <DefaultLayout breadcrumb={breadcrumb}>
      <PageTitle title={t('menus:Area')} />
      <Filter isLoading={loading} setFilters={setFilters} />
      <Box>
        <Row>
          <Col span={12} style={style.btnNew.col}>
            <Button
              loading={loading}
              color="primary"
              onClick={() => {
                setSelectedRecord(null);
                setShowForm(true);
              }}
              style={style.btnNew.btn}
            >
              <PlusOutlined />
              {t('screens:area.btnNew')}
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
          onChange={handleTableChange}
        />
        <Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />

        <AreaForm
          selectedRecord={selectedRecord}
          visible={showForm}
          onClose={() => setShowForm(false)}
          onInsertItem={item => {
            setTableData([...tableData, item]);
          }}
          onUpdateItem={item => onUpdateItem(item)}
        />
      </Box>
    </DefaultLayout>
  );
}
