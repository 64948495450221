import React, { useState, useRef } from 'react';
import {
  Col,
  Modal,
  Spin,
  Divider,
  Input as InputAntd,
  Select as SelectAntd,
  InputNumber,
  Space,
  Button as ButtonAntd,
  message,
  Checkbox,
} from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormControl from '~/components/Form/FormControl';
import FormTxt from '~/components/Form/FormTxt';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import { PlusOutlined } from '@ant-design/icons';
// import { unitList } from '~/services/lists/unit';
import { updateEstimate } from '~/services/hooks/estimates';
import { createArea } from '~/services/hooks/areas';
// import { paginationEstimateDescriptions } from '~/services/hooks/estimateDescriptions';
import { formatPrice } from '~/Utils';

const initialValuesItem = {
  id: null,
  area: {
    id: null,
    name: '',
  },
  laborType: {
    id: null,
    code: null,
    name: '',
  },
  type: 'LABOR',
  markup: 0,
  markupType: 'DOUBLE_DASH',
  estimateDescription: {
    id: null,
    laborType: {
      id: null,
      code: null,
      name: '',
    },
    unit: 'SF',
    description: '',
    materialValue: 0,
    laborValue: 0,
  },
  quantity: 0,
  materialValue: 0,
  laborValue: 0,
};

export default function EstimateForm({
  selectedRecord,
  laborTypes,
  areas,
  setAreas,
  estimateData,
  estimateDescriptions,
  setEstimateData,
  onClose,
  visible,
}) {

  if (laborTypes.length > 0) {
    laborTypes = laborTypes.map(laborType => ({
      id: laborType.id,
      code: laborType.code,
      name: laborType.name,
    }));
  }

  if (areas.length > 0) {
    areas = areas.map(area => ({
      id: area.id,
      name: area.name,
    }));
  }
  const recordData = { ...initialValuesItem, ...selectedRecord };
  const { Option } = Select;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const selectRef = useRef(null);
  const [areaName, setAreaName] = useState();

  const onAreaNameChange = event => {
    setAreaName(event.target.value);
  };

  const handleAddArea = async setFieldValue => {
    // verify if the area already exists
    const areaExists = areas.find(area => area.name === areaName);
    if (areaExists) {
      message.warning('Area already exists');
      return;
    }
    // create the new area in the database
    const data = await createArea({ id: null, name: areaName });
    setAreas([...areas, data]);
    setAreaName('');
    setFieldValue('area', data);
    // setTimeout(() => {
    //   selectRef.current.focus();
    // }, 0);
  };

  const handleSave = async values => {
    setLoading(true);
    if (values.id !== null) {
      // estimateData with the updated item
      const updateEstimateData = {
        ...estimateData,
        items: estimateData.items.map(item => {
          if (item.id === values.id) {
            return values;
          }
          return item;
        }),
      };
      // update the estimate in the database
      const data = await updateEstimate(updateEstimateData);
      setEstimateData(data);
    } else {
      // estimateData with the new item
      const updateEstimateData = {
        ...estimateData,
        items: [...estimateData.items, values],
      };
      // update the estimate in the database
      const data = await updateEstimate(updateEstimateData);
      setEstimateData(data);
    }
    setLoading(false);
    onClose();
  };

  const estimateSchema = Yup.object().shape({
    area: Yup.object().shape({
      id: Yup.number().required(t('screens:estimate.areaRequired')),
      name: Yup.string().required(t('screens:estimate.areaRequired')),
    }),
    laborType: Yup.object().shape({
      id: Yup.number().required(t('screens:estimate.laborTypeRequired')),
      name: Yup.string().required(t('screens:estimate.laborTypeRequired')),
    }),
    estimateDescription: Yup.object().shape({
      id: Yup.number().required(t('screens:estimate.descriptionRequired')),
      description: Yup.string().required(t('screens:estimate.descriptionRequired')),
    }),
  });

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
      initialValues={recordData}
      onSubmit={handleSave}
      validationSchema={estimateSchema}
    >
      {({ errors, isSubmitting, submitForm, resetForm, touched, values, setFieldValue }) => (
        <Modal
          width="600px"
          title={selectedRecord !== null ? t('screens:estimate.edit') : t('screens:estimate.new')}
          description={selectedRecord !== null ? t('screens:estimate.btnEdit') : t('screens:estimate.btnNew')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col span={24}>
                  {/* AREA */}
                  <FormControl
                    field="area.id"
                    label={t('screens:estimate.data.area')}
                    required
                    error={(touched.area?.id && errors.area?.id) || errors.area?.id}
                  >
                    <SelectAntd
                      placeholder="Select area"
                      value={values.area?.id}
                      dropdownRender={menu => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: '8px 0',
                            }}
                          />
                          <Space
                            style={{
                              padding: '0 8px 4px',
                            }}
                          >
                            <InputAntd
                              placeholder="Enter new area"
                              ref={inputRef}
                              value={areaName}
                              onChange={e => {
                                onAreaNameChange(e);
                              }}
                              // onPressEnter={() => {
                              //   handleAddArea(setFieldValue);
                              // }}
                            />
                            <ButtonAntd
                              type="text"
                              icon={<PlusOutlined />}
                              onClick={() => {
                                handleAddArea(setFieldValue);
                              }}
                            >
                              Add area
                            </ButtonAntd>
                          </Space>
                        </>
                      )}
                      options={areas?.map(a => ({
                        label: a.name,
                        value: a.id,
                      }))}
                      onChange={value => {
                        setFieldValue(
                          'area',
                          areas.find(item => item.id === value)
                        );
                      }}
                    />
                    {/* <Select
                      name="area.id"
                      id="area.id"
                      placeholder="Select"
                      value={values.area?.id}
                      onChange={value => {
                        values.area = areas.find(item => item.id === value);
                      }}
                    >
                      {areas?.map(item => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select> */}
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {/* SERVICE TYPE */}
                  <FormControl
                    field="laborType.id"
                    label={t('screens:estimate.data.laborType')}
                    required
                    error={(touched.laborType?.id && errors.laborType?.id) || errors.laborType?.id}
                  >
                    <Select
                      name="laborType.id"
                      id="laborType.id"
                      placeholder="Select"
                      value={values.laborType?.id}
                      onChange={value => {
                        setFieldValue(
                          'laborType',
                          laborTypes.find(item => item.id === value)
                        );
                        // if value changes, reset the description
                        setFieldValue('estimateDescription', { ...initialValuesItem.estimateDescription });
                      }}
                    >
                      {laborTypes?.map(item => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {/* DESCRIPTION */}
                  <FormControl
                    field="estimateDescription"
                    label={t('screens:estimate.data.description')}
                    required
                    error={
                      (touched.estimateDescription?.description && errors.estimateDescription?.description) ||
                      errors.estimateDescription?.description
                    }
                  >
                    <Select
                      name="estimateDescription"
                      id="estimateDescription"
                      placeholder={!values.laborType?.id ? 'Select service type first' : 'Select'}
                      value={values.estimateDescription?.description}
                      onChange={value => {
                        setFieldValue(
                          'estimateDescription',
                          estimateDescriptions.find(item => item.description === value)
                        );
                        setFieldValue(
                          'materialValue',
                          estimateDescriptions.find(item => item.description === value).materialValue
                        );
                        setFieldValue(
                          'laborValue',
                          estimateDescriptions.find(item => item.description === value).laborValue
                        );
                      }}
                      disabled={!values.laborType?.id}
                    >
                      {estimateDescriptions
                        .filter(item => item.laborType.id === values.laborType?.id)
                        .map(item => {
                          return (
                            <Option key={item.id} value={item.description}>
                              {item.description}
                            </Option>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={4}>
                  {/* UNIT */}
                  <FormTxt label={t('screens:estimate.data.unit')}>
                    <>{values.estimateDescription.unit}</>
                  </FormTxt>
                </Col>
                <Col span={6}>
                  {/* QUANTITY */}
                  <FormControl
                    field="quantity"
                    label={t('screens:estimate.data.quantity')}
                    // required
                    error={(touched.quantity && errors.quantity) || errors.quantity}
                  >
                    <InputNumber
                      name="quantity"
                      id="quantity"
                      placeholder="Enter quantity"
                      value={values.quantity}
                      onChange={value => {
                        setFieldValue('quantity', value);
                      }}
                    />
                  </FormControl>
                </Col>
                <Col span={14}>
                  {/* COPY QUANTITY TO VENDOR */}
                  <FormControl
                    field="copyQtdToVendor"
                    label={t('screens:estimate.data.copyQtdToVendor')}
                    // required
                    error={(touched.copyQtdToVendor && errors.copyQtdToVendor) || errors.copyQtdToVendor}
                  >
                    <Checkbox
                      name="copyQtdToVendor"
                      id="copyQtdToVendor"
                      checked={values.copyQtdToVendor}
                      onChange={e => {
                        setFieldValue('copyQtdToVendor', e.target.checked);
                      }}
                    />
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  {/* MATERIAL UNIT PRICE */}
                  <FormControl
                    field="materialValue"
                    label={t('screens:estimate.data.materialValue')}
                    // required
                    error={(touched.materialValue && errors.materialValue) || errors.materialValue}
                  >
                    <InputNumber
                      addonBefore="$"
                      name="materialValue"
                      id="materialValue"
                      placeholder="Enter material unit cost"
                      value={values.materialValue}
                      onChange={value => {
                        setFieldValue('materialValue', value);
                      }}
                    />
                  </FormControl>
                </Col>

                <Col span={8}>
                  {/* LABOR UNIT PRICE */}
                  <FormControl
                    field="laborValue"
                    label={t('screens:estimate.data.laborValue')}
                    // required
                    error={(touched.laborValue && errors.laborValue) || errors.laborValue}
                  >
                    <InputNumber
                      addonBefore="$"
                      name="laborValue"
                      id="laborValue"
                      placeholder="Enter labor unit cost"
                      value={values.laborValue}
                      onChange={value => {
                        setFieldValue('laborValue', value);
                      }}
                    />
                  </FormControl>
                </Col>

                <Col span={8}>
                  {/* TOTAL UNIT */}
                  <FormTxt label={t('screens:estimate.data.totalUnit')}>
                    <>
                      {formatPrice(
                        parseFloat(
                          (values.materialValue !== undefined ? values.materialValue : 0) +
                            (values.laborValue !== undefined ? values.laborValue : 0)
                        )
                      )}
                    </>
                  </FormTxt>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  {/* TOTAL MATERIAL PRICE */}
                  <FormTxt label={t('screens:estimate.data.totalMaterial')}>
                    <>
                      {formatPrice(
                        parseFloat(
                          values.materialValue === undefined || values.quantity === undefined
                            ? 0
                            : values.materialValue * values.quantity
                        )
                      )}
                    </>
                  </FormTxt>
                </Col>

                <Col span={8}>
                  {/* TOTAL LABOR PRICE */}
                  <FormTxt label={t('screens:estimate.data.totalLabor')}>
                    <>
                      {formatPrice(
                        parseFloat(
                          values.laborValue === undefined || values.quantity === undefined
                            ? 0
                            : values.laborValue * values.quantity
                        )
                      )}
                    </>
                  </FormTxt>
                </Col>

                <Col span={8}>
                  {/* TOTAL PRICE */}
                  <FormTxt label={t('screens:estimate.data.totalMaterialAndLabor')}>
                    <>{formatPrice(
                      parseFloat(
                        values.quantity !== undefined
                          ? values.quantity *
                              ((values.materialValue !== undefined ? values.materialValue : 0) +
                                (values.laborValue !== undefined ? values.laborValue : 0))
                          : 0
                      )
                    )}</>
                  </FormTxt>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
