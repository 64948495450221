import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined, EyeOutlined, ApiOutlined, PlusOutlined } from '@ant-design/icons';
import { Popconfirm, Breadcrumb, Tag, Tooltip, Button as ButtonAntd, Col } from 'antd';
import DefautLayout from '~/pages/_layouts/full';
import Box from '~/components/Box';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Pagination from '~/components/Pagination';
import { Table, TableActions } from '~/components/Table';
import ProjectForm from './form';
import { BreadcrumbCustom } from '~/styles/global';
import history from '~/services/history';
import Filter from './filter';
import Row from '~/components/Row';
import { getProjectStatusColor } from '~/Utils';
import { projectStatus } from '~/services/lists/projectStatus';
import { paginationProjects, deleteProject, findAllProjectTypes } from '~/services/hooks/projects';

export default function ProjectsList(data) {
  const customer = data.location.state.data;
  delete customer.projects; // remove projects list from customer data
  const customerId = customer.id;
  const customerName = customer.companyName;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [sortBy, setSortBy] = useState('description');
  const defaultFilter = { field: 'flag', value: 'PROJECT', restriction: 'NOT_EQUAL' };
  const defaultFilterCustomer =
    customerId !== undefined ? { columnJoin: 'customer', field: 'id', value: customerId, restriction: 'EQUAL' } : {};
  const [filters, setFilters] = useState([]);

  const fetchData = async page => {
    setLoading(true);
    const data = await paginationProjects({
      page: page - 1,
      sortBy,
      filters: [...filters, defaultFilter, defaultFilterCustomer],
    });
    setTableData(data.content);
    const metaData = {
      first: data.first,
      last: data.last,
      numberOfElements: data.numberOfElements,
      pageNumber: data.pageable.pageNumber + 1,
      pageSize: data.pageable.pageSize,
      totalElements: data.totalElements,
    };
    setMeta(metaData);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    findAllProjectTypes().then(data => {
      setProjectTypes(data);
    });
    setLoading(false);
  }, []);

  const handleDelete = async id => {
    await deleteProject(id);
    fetchData(1);
  };

  const handleEdit = record => {
    setSelectedRecord(record);
    setShowForm(true);
  };

  useEffect(() => {
    fetchData(1);
  }, [filters, sortBy]);

  const style = {
    pageTitle: { marginRight: 'auto', width: '100%' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
  };

  const getTag = record => {
    if (record === undefined) return null;
    const flag = record.flag;
    let icon,
      color,
      title = null;

    if (flag === 'PROJECT') {
      return null;
      // title = 'Project';
      // icon = <BulbOutlined />;
      // color = 'blue';
    } else if (flag === 'BID') {
      return null;
      // title = 'Bid';
      // icon = <ApiOutlined />;
      // color = 'green';
    } else {
      title = 'Proposal Rejected';
      icon = <ApiOutlined />;
      color = 'red';
    }
    return (
      <Tooltip title={title}>
        <Tag icon={icon} color={color}>
          {title}
        </Tag>
      </Tooltip>
    );
  };

  const tableColumns = [
    {
      title: t('screens:bids.data.description'),
      dataIndex: 'description',
      key: 'description',
      render: (text, record) => (
        <>
          <ButtonAntd
            type="link"
            onClick={() =>
              history.push({
                pathname: '/bid-details',
                state: { data: record, customer: customer },
              })
            }
            title={t('messages:view')}
          >
            {text}
          </ButtonAntd>
          {getTag(record)}
        </>
      ),
    },
    {
      title: t('screens:bids.data.type'),
      dataIndex: 'type',
      key: 'type',
      render: type => {
        const projectType = projectTypes.find(projectType => projectType.name === type);
        return projectType ? projectType.description : null;
      },
    },
    {
      title: t('screens:bids.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '100px',
      render: status => (
        <Tag color={getProjectStatusColor(status?.toLowerCase())}>
          {t('screens:bids.data.status.' + status?.toLowerCase()).toUpperCase()}
        </Tag>
      ),
    },
    {
      title: t('screens:bids.data.actions'),
      key: 'actions',
      align: 'center',
      width: '10%',
      className: 'table-action',
      render: record => (
        <TableActions>
          <Button
            onClick={() =>
              history.push({
                pathname: '/bid-details',
                state: { data: record, customer: customer },
              })
            }
            title={t('messages:view')}
          >
            <EyeOutlined />
          </Button>
          <Button onClick={() => handleEdit(record)} title={t('messages:edit')}>
            <EditOutlined />
          </Button>
          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button title={t('messages:delete')}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </TableActions>
      ),
    },
  ];

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:projects')}</Breadcrumb.Item>
      <Breadcrumb.Item href="/bids">{t('menus:bids')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:bidList')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  return (
    <DefautLayout breadcrumb={breadcrumb}>
      {customerName !== undefined && customerName !== null && customerName !== '' ? (
        <PageTitle title={customerName} subtitle={t('menus:bidList')} />
      ) : (
        <PageTitle title={t('menus:bidList')} />
      )}
      <Filter projectStatus={projectStatus} isLoading={loading} setFilters={setFilters} />
      <Box>
        <Row>
          <Col span={12} style={style.btnNew.col}>
            <Button
              loading={loading}
              color="primary"
              onClick={() => {
                setSelectedRecord(null);
                setShowForm(true);
              }}
              style={style.btnNew.btn}
            >
              <PlusOutlined />
              {t('screens:bids.btnNew')}
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
        />
        <Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />
      </Box>
      <ProjectForm
        record={selectedRecord}
        projectTypes={projectTypes}
        visible={showForm}
        onClose={() => {
          setShowForm(false);
          setSelectedRecord(null);
          fetchData(1);
        }}
        customerId={customerId}
      />
    </DefautLayout>
  );
}

// when passing data from another page, for example, customer data with projects list ( history.push({pathname: '/projects-list', state: { data: record },}) ), use the following line
// const [tableData, setTableData] = useState(data.location.state.data.projects.filter(project => project.flag === 'PROJECT'));
